<template>
  <div></div>
</template>

<script>
import { services } from "@/main";
export default {
  data() {
    let next = this.$route.query.next;
    services.authenticationService.handleAuthentication(next);
    return {};
  },
};
</script>
